<template>
    <div class="comp">
        <div class="comp-container">
            <h2> پیام ها </h2>
            <div class="notifications">
                <div v-for="(item,index) in notifs" :key="index" class="notification">
                    <div class="title">
                        <span> {{item.title}} </span>
                        <span> {{$G2J(item.createdAtDateTime)}} </span>
                    </div>
                    <p> {{item.text}} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Notifications',
        data() {
            return {
                    notifs:[
                        {
                            createdAtDateTime:"2022-05-21T09:06:48",
                            title:'احراز هویت شما تایید شد',
                            text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه ',
                        },
                        {
                            createdAtDateTime:"2022-05-21T09:06:48",
                            title:'احراز هویت شما تایید شد',
                            text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه ',
                        },
                        {
                            createdAtDateTime:"2022-05-21T09:06:48",
                            title:'احراز هویت شما تایید شد',
                            text:'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه ',
                        },
                    ]
            }
        },
    }
</script>

<style lang="scss" scoped>
h2{
    font-size: clamp(16px,2vw,20px);
}
.comp-container{
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
    justify-content: start;
}
.comp{
    min-height: 92vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.notifications{

    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 12px;
    padding: 24px;
    row-gap: 24px;
}
.notification{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border-bottom: 1px solid var(--light-grey);
    color: var(--grey);
    .title{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
@media only screen and(max-width:1000px){
    .title{
        flex-direction: column !important;
        row-gap: 20px !important;
    }
}
</style>